import React, { useEffect, useMemo } from 'react'
import RouteBuilder from '@/components/RouteBuilder'
import { useUser, parseJwt } from '@/context/UserProvider'
import { createCookie, getCookies } from '@/helpers/cookies'
import { checkRefreshToken } from '@/helpers/checkRefreshToken'
import { PageWrapper, ContentWrapper } from './App.styles'
import { ROUTES } from '@/constants/routes'
import ScrollToTop from '@/components/ScrollToTop'
import UpdateContractsPage from '@/pages/UpdateContractsPage'
import { Header, Footer, Spinner } from '@/components/__UI'
import { MenuProvider } from '../__UI/Header/context/context'
import { RefetchProvider } from '../../context/RefetchProvider/RefetchProvider'
import { useLocation } from 'react-router-dom'
import { getLoginUrl } from '@/helpers/authorisations'
import { getEnvironment } from '@/helpers/environment'
import SignInLocalhost from '../SignInLocal/SignInLocalhost'
import { useQueryUserContracts, useQueryMaintenanceStatus } from '@/hooks/useQuery'
import theme from '@/global/Theme'
import { DebugBar } from '@/components/DebugBar'
import { setLocale } from '@/helpers/locale'
import AdminBar from '../__UI/AdminBar/AdminBar'
import { useOwnerServicesCookies } from '@/pages/OwnerServiceRedirect/useOwnerServicesCookies'
import { SelectedPropertyProvider } from '@/context/SelectedPropertyProvider'

const App = () => {
  const idToken = getCookies('idToken')
  const { user, hasPermission, loading: isLoadingUser, refetching, logout, isLoggingOut } = useUser()
  const { isLoading: isLoadingMaintenanceStatus } = useQueryMaintenanceStatus()
  const STANDARD_USER_DEFAULT_PAGE = '/bookings/list'
  const ADMIN_USER_DEFAULT_PAGE = '/user-admin'
  const location = useLocation()
  useOwnerServicesCookies()

  const redirectToRef = React.useRef(null)

  useEffect(() => {
    setLocale()
  }, [])

  const hideHeaderFooter = useMemo(() => {
    const params = new URLSearchParams(location.search)
    const redirectTo = params.get('redirectTo')
    redirectToRef.current = redirectTo

    return params.get('utm_source') === 'app' && params.get('utm_medium') === 'app'
  }, [location.search])

  useEffect(() => {
    const checkTokens = async () => {
      await checkRefreshToken(logout)
    }

    if (getEnvironment() === 'localhost') {
      checkTokens()
    }
  }, [logout])

  useEffect(
    function setMobileStatusBarColor () {
      let metaThemeColor = document.querySelector('meta[name=theme-color]')
      if (!metaThemeColor) {
        metaThemeColor = document.createElement('meta')
        metaThemeColor.setAttribute('name', 'theme-color')
        document.getElementsByTagName('head')[0].appendChild(metaThemeColor)
      }
      metaThemeColor.setAttribute('content', theme.ColorThemeBackgroundHeader)
    },
    []
  )

  const { data: userContracts, isLoading: isLoadingUserContracts, refetch: reloadContracts, isFetching } = useQueryUserContracts(!isLoadingUser && !!user)

  const isAdminUserRedirect = () => {
    const adminPermission = hasPermission('Admin')
    const siteAdminPermission = hasPermission('SiteAdmin')

    if (adminPermission && !siteAdminPermission) {
      return true
    }

    return false
  }

  // User data is loading
  if (isLoadingUser || refetching || (user && isLoadingUserContracts) || isFetching || isLoadingMaintenanceStatus) {
    return (
      <PageWrapper noBackground>
        <Spinner large />
      </PageWrapper>
    )
  }

  // User is not signed in
  if (!user) {
    if (idToken) {
      const idTokenJWT = parseJwt(idToken)
      const oneYear = new Date()

      oneYear.setFullYear(oneYear.getFullYear() + 1)
      createCookie('LastAuthUser', idTokenJWT.sub, oneYear, '/')

      return <></>
    }

    // Localhost signin for developers
    if (getEnvironment() === 'localhost') {
      return <SignInLocalhost />
    }

    let redirectUrl = getLoginUrl()

    const pathsThatDontNeedRedirectQs = ['/', '/forgotten-password', '/activate-account', '/verify-account']
    if (!pathsThatDontNeedRedirectQs.includes(window.location.pathname)) {
      redirectUrl = `${getLoginUrl()}?redirectTo=${location.pathname}`
    }

    if (!isLoggingOut) {
      window.location = redirectUrl
    }
  }

  if (user) {
    if (userContracts && userContracts.length > 0) {
      return <UpdateContractsPage contracts={userContracts} reloadContracts={reloadContracts} />
    }

    return (
      <SelectedPropertyProvider>
        <MenuProvider>
          <RefetchProvider>
              <AdminBar />
              {!hideHeaderFooter && <Header />}
              <ContentWrapper>
                <ScrollToTop />
                <RouteBuilder
                  localLink={localStorage.getItem('lastClickedLink')}
                  routes={ROUTES}
                  redirectPath={isAdminUserRedirect() ? ADMIN_USER_DEFAULT_PAGE : STANDARD_USER_DEFAULT_PAGE}
                />
              </ContentWrapper>
              {!hideHeaderFooter && <Footer />}
              <DebugBar />
          </RefetchProvider>
        </MenuProvider>
      </SelectedPropertyProvider>
    )
  }

  return (
    <PageWrapper noBackground>
      <Spinner large />
    </PageWrapper>
  )
}

App.displayName = 'App'

export default App
