import React, { useState, useCallback } from 'react'
import { useCallToArmsContext } from '../context/CallToArmsProvider'
import { Link } from 'react-router-dom'
import {
    CallToArmsMessageModal,
    RecommendedPricesButton,
    ButtonsContainer,
} from './CallToArmsModal.styles'
import { Divider } from '@/components/__UI'

const CallToArmsModal = () => {
    const { showRecommendationButton } = useCallToArmsContext()
    const [visible, setVisible] = useState(true)

    const closeMessageHandlerWrapper = useCallback(() => {
        const date = new Date()
        date.setFullYear(date.getFullYear() + 1)
        setVisible((prev) => !prev)
        sessionStorage.setItem('messageShownOnce', true)
    }, [])

    return (
        <>
            {showRecommendationButton &&
                visible &&
                !sessionStorage.getItem('messageShownOnce') && (
                    <CallToArmsMessageModal
                        open={showRecommendationButton}
                        strict
                    >
                        <div className='messageRecommendation'>
                            <h4>
                                IMPORTANT - please review and respond to our
                                pricing proposal for 2025 and 2026
                            </h4>
                            <p>
                                We have prepared a tailored pricing
                                recommendation to set you up for success in 2025
                                and 2026. Please take a few minutes to review
                                our suggestions and respond before the deadline
                                mentioned in your accompanying email.
                            </p>
                        </div>
                        <Divider size={3} />
                        <ButtonsContainer>
                            <RecommendedPricesButton
                                data-testid='callToArmsContinueBrowsingButton'
                                onClick={closeMessageHandlerWrapper}
                                primaryLine
                            >
                                Continue browsing
                            </RecommendedPricesButton>
                            {
                                <Link to='/pricing-list/price-recommendations'>
                                    <RecommendedPricesButton
                                        data-testid='callToArmsNewPricesButton'
                                        primary
                                    >
                                        Review price recommendations
                                    </RecommendedPricesButton>
                                </Link>
                            }
                        </ButtonsContainer>
                    </CallToArmsMessageModal>
                )}
        </>
    )
}

export default CallToArmsModal
