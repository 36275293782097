
import styled from 'styled-components'
import { Text } from '@awaze/design-system.components.text';


/* stylelint-disable-line block-no-empty */
const MainHeaderSelectContainer = styled.article``

const ExplanationText = styled.div`
  margin-bottom: 1rem;
  text-align: justify;
`


const BulletText = styled(Text)`
  padding-left: 1rem; 

  &::before {
    content: "•"; 
    position: absolute;
    left: 0;
  }
`;
export { MainHeaderSelectContainer, ExplanationText, BulletText };
