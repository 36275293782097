import React from 'react';
import {
  TableHeaderMobile,
  TableHeaderCellMobile,
  TableHeaderDesktopDIV,
  TableHeaderDesktop,
  MobileHeader,
  Currency,
} from './PriceRecommendationsTable.styles';
import PropTypes from 'prop-types';
import Table from '@/components/Table';
import Spinner from '@/components/__UI/Spinner';
import useWindowSize from '@/hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import CurrencyIcon from './CurrencyIcon/CurrencyIcon';
import { useQueryPricingData } from '@/hooks/useQuery';
import PriceRecommendationTableItem from '../PriceRecommendationsTableItem/PriceRecommendationItem';
import useUserBrand from '@/hooks/useUserBrand/useUserBrand';

import {
  getIncludedColumnsDesktop,
  getIncludedColumnsMobile,
} from '../PriceRecommendationHelpers/recommendationTableHelperMethods';

const PriceRecommendationsTable = ({ duration, property, year }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isDesktop = width > 599;
  const { isUserBrandNovasol } = useUserBrand();

  const { headersData, recommendationsData, isLoading } = useQueryPricingData(
    property,
    year,
    duration.value
  );

  const columns = isDesktop ? headersData?.columnCount : 3;

  const filteredRecommendations = recommendationsData?.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.season === item.season)
  );
  const recommendations = isUserBrandNovasol
    ? filteredRecommendations
    : recommendationsData;

  const renderCurrency = () => (
    <Currency data-testid="currency">
      <CurrencyIcon />
      <span>
        {t('allPricesAreIn')} {headersData?.currency || 'GBP'}
      </span>
    </Currency>
  );

  const renderTableHeaders = (header, index) => {
    if (
      isDesktop &&
      !getIncludedColumnsDesktop(isUserBrandNovasol).includes(
        header.columnIndex
      )
    ) {
      return null;
    }

    if (isDesktop) {
      return (
        <Table.HeadCell data-testid="prTableHeadCell" key={index}>
          {t(`${header.prefixText ?? ''}${header.suffixText ?? ''}`, {
            year: header.yearText,
          })}
        </Table.HeadCell>
      );
    } else {
      return (
        <TableHeaderCellMobile
          data-testid="prTableHeadCell"
          align="flex-start"
          key={index}
        >
          <div>
            {header.prefixText === 'weekCommencing'
              ? t('week')
              : t(`${header.prefixText ?? ''}${header.suffixText ?? ''}`, {
                  year: header.yearText,
                })}
          </div>
        </TableHeaderCellMobile>
      );
    }
  };

  const commonContent = isLoading ? (
    <Spinner large />
  ) : (
    <>
      {isDesktop ? (
        <TableHeaderDesktopDIV data-testid={'desktop'}>
          {renderCurrency()}
          <TableHeaderDesktop>
            {headersData?.columnHeaders?.map((header, index) =>
              renderTableHeaders(header, index)
            )}
          </TableHeaderDesktop>
        </TableHeaderDesktopDIV>
      ) : (
        <MobileHeader data-testid={'mobile'}>
          {renderCurrency()}
          <TableHeaderMobile>
            {headersData?.columnHeaders
              ?.filter((index) =>
                getIncludedColumnsMobile(isUserBrandNovasol).includes(
                  index.columnIndex
                )
              )
              .map((header, index) => renderTableHeaders(header, index))}
          </TableHeaderMobile>
        </MobileHeader>
      )}

      {recommendations?.map((item, index) => (
        <PriceRecommendationTableItem
          key={index}
          itemData={item}
          columnHeaders={headersData?.columnHeaders || []}
        />
      ))}
    </>
  );

  if (!property || !year || !duration) {
    return (
      <div data-testid="error">{t('errorMessageForPriceRecommendations')}</div>
    );
  }

  return <Table columns={columns}>{commonContent}</Table>;
};

PriceRecommendationsTable.propTypes = {
  duration: PropTypes.object,
  property: PropTypes.object,
  year: PropTypes.object,
};

export default PriceRecommendationsTable;
