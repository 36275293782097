// Price recommendation table constants
export const PRICE_REC_TABLE_INCLUDED_NOVASOL_COLUMNS_DESKTOP = [2, 4, 5, 6]
export const PRICE_REC_TABLE_INCLUDED_AWAZE_COLUMNS_DESKTOP = [1, 2, 3, 4, 5, 6]
export const PRICE_REC_TABLE_INCLUDED_NOVASOL_COLUMNS_MOBILE = [2, 4, 6]
export const PRICE_REC_TABLE_INCLUDED_AWAZE_COLUMNS_MOBILE = [1, 4, 6]

// Single price recommendation item row constants
export const PRICE_REC_ITEM_INCLUDED_NOVASOL_COLUMN_CELLS_DESKTOP = [1, 2, 3]
export const PRICE_REC_ITEM_INCLUDED_AWAZE_COLUMN_CELLS_DESKTOP = [0, 1, 2, 3]
export const PRICE_REC_ITEM_ACHIEVED_PRICE_COLUMN_INDEX = 1;
export const PRICE_REC_ITEM_PROPOSED_PRICE_COLUMN_INDEX = 3
export const PRICE_REC_ITEM_INCLUDED_NOVASOL_COLUMNS_MOBILE = [2, 3]
export const PRICE_REC_ITEM_INCLUDED_AWAZE_COLUMNS_MOBILE = [1, 3]
export const PRICE_REC_ITEM_INCLUDED_AWAZE_COLUMNS_MOBILE_COLLAPSED = [0, 1, 2, 3]
export const PRICE_REC_ITEM_INCLUDED_NOVASOL_COLUMNS_MOBILE_COLLAPSED = [1, 2, 3]
