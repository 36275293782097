import styled from 'styled-components';
import DesignTokens from '@/global/DesignTokens';
import OvalLoadingSpinner from '@/components/__UI/OvalLoadingSpinner';
import * as BookingTypes from '@/constants/bookings';
import { Text } from '@awaze/design-system.components.text';

const Wrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  text-align: center;
  height: 100%;

  ${({ theme }) => theme.mediaQueries.phoneDS} {
    text-align: left;
    background-color: transparent;
  }

  ${({ theme }) => theme.mediaQueries.tabletDS} {
    background-color: transparent;
  }
`;

const WeekDay = styled.th`
  font-weight: ${({ day }) => (day === 'sa' || day === 'su' ? '600' : '400')};
  font-size: 12px;
`;

const MonthTitle = styled.span`
  display: block;
  font-size: 1.2em;

  ${({ theme }) => theme.mediaQueries.tabletDS} {
    ${({ theme }) => theme.fonts.Title400};
    text-align: left;
    margin-left: 12px;
  }

  ${({ theme }) => theme.mediaQueries.phoneDS} {
    margin-left: 24px;
  }
`;

const StyledSpinner = styled(OvalLoadingSpinner)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const TableWrapper = styled.div`
  position: relative;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  display: block;
  height: 100%;
  flex: 1;

  ${({ theme }) => theme.mediaQueries.phoneDS} {
    width: 100% !important;
  }
`;

const Table = styled.table`
  position: relative;
  width: 100%;
  height: auto;
  table-layout: fixed;
`;

const DayBar = styled(Table)`
  height: auto;
  th {
    padding: 1em 0;
  }

  ${({ theme }) => theme.mediaQueries.phoneDS} {
    margin-left: 24px;
  }
`;

const Td = styled.td`
  ${({
    theme,
    unavailable,
    inActive,
    disabled,
    yearCalendar,
    blankCell,
    closed,
    isAvailable,
  }) => `
        ${
          !inActive && !disabled
            ? `
          &:hover,
          &:focus,
          &:active {
              cursor: ${
                unavailable || closed
                  ? 'not-allowed'
                  : 'pointer'
              };
              background-color: ${theme.colors.greyFour};
          }
        `
            : ''
        }

        position: relative;
        padding: .25rem 0;
        height: 50px;
        transition: ${theme.animationSpeed} all;
        font-size: 0.875rem;
        background-color: ${
          unavailable || closed ? theme.colors.buttonSolidTertiary : ''
        };
        
        border: 1px solid ${theme.colors.greyFour};
        ${
          yearCalendar
            ? `
          height: 28px;
          font-size: 11px;

          ${
            blankCell
              ? `
            background-color: ${theme.ColorGrey200};
          `
              : ``
          }
        `
            : ``
        }
    `}

  ${({ theme }) => theme.mediaQueries.tabletDS} {
    background-color: ${({ theme, unavailable, closed, blankCell }) =>
      !unavailable && !closed && !blankCell ? theme.colors.white : ''};
    text-align: center;

    ${({ blankCell }) =>
      blankCell
        ? `
      color: transparent;
      pointer-events: none;
       border: transparent;
    `
        : ''}
  }

  ${({ theme }) => theme.mediaQueries.phoneDS} {
    background-color: ${({ theme, unavailable, closed, blankCell }) =>
      !unavailable && !closed && !blankCell ? theme.colors.white : ''};
    text-align: center;

    ${({ blankCell }) =>
      blankCell
        ? `
      color: transparent;
      pointer-events: none;
       border: transparent;
    `
        : ''}
  }
`;


const DayNumber = styled.span`
  ${({ theme, isHovered, closed, unavailable }) => `
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 30px;
      font-weight: 600;
      padding: 0;
      height: 1em;
      cursor: ${isHovered ? 'pointer' : 'default'};
      color: ${unavailable ? theme.ColorBlackOpacity60 : theme.ColorGrey800};
      pointer-events: none;
      text-decoration: ${closed ? 'line-through' : 'none'};
    `}
`;

function getSegmentBackgroundColor(theme, bookingState) {
  switch (bookingState) {
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_CUSTOMER:
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_CUSTOMER_WITH_CLEAN:
      return theme.colors.customerSegmentBackground;
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER:
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER_WITH_CLEAN:
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER_COMMISSION:
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER_RESERVED:
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_UNCHECKED_OWNER:
      return theme.colors.ownerSegmentBackground;
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_PROVISIONAL:
      return theme.colors.provisionalSegmentBackground;
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_CANCELLED:
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_EXPIRED:
      return theme.colors.cancelled;
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_OTHER:
      return theme.gradient.candyCaneCalendar;
    default:
      break;
  }
}

function getSegmentHoverBorderColor(theme, bookingState) {
  switch (bookingState) {
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_CUSTOMER:
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_CUSTOMER_WITH_CLEAN:
      return theme.colors.customerSegmentBorder;
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER:
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER_WITH_CLEAN:
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER_COMMISSION:
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER_RESERVED:
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_UNCHECKED_OWNER:
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_OTHER:
      return theme.colors.ownerSegmentBorder;
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_PROVISIONAL:
      return theme.colors.provisionalSegmentBorder;
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_CANCELLED:
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_EXPIRED:
      return theme.colors.cancelled;
    default:
      break;
  }
}

const Segment = styled.div`
  ${({ theme, type, $isHovered, $state, yearCalendar, changeover }) => `
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: ${
      type === 'END' || type === 'START'
        ? (yearCalendar ? 9 : 12.5) + 'px'
        : 'calc(100% + 1px)'
    };
    height: ${yearCalendar ? 20 : 35.5}px;
    background: ${getSegmentBackgroundColor(theme, $state)};
    cursor: pointer;
    
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-style: none;
    border-color: ${getSegmentHoverBorderColor(theme, $state)};

    ${
      changeover
        ? `
    background: ${theme.colors.changeOverSegmentBackground};
    width: 100%;
    `
        : ''
    }
    
    left: unset;
    ${
      type === 'START'
        ? `
        right: -1px;
        border-top-left-radius: ${yearCalendar ? 2 : 4}px;
        border-bottom-left-radius: ${yearCalendar ? 2 : 4}px;
        border-left-width: 1px;
    `
        : ''
    }

    ${
      type === `END`
        ? `
      border-top-right-radius: ${yearCalendar ? 2 : 4}px;
      border-bottom-right-radius: ${yearCalendar ? 2 : 4}px;
      border-right-width: 1px;
    `
        : ''
    }

    ${theme.mediaQueries.phoneDS}{
      ${
        !changeover
          ? `
       width: ${
         type === 'END' || type === 'START' ? '22.5px' : 'calc(100% + 1px)'
       };
      `
          : ''
      }
   }
    ${theme.mediaQueries.tabletDS}{ 
  ${
    !changeover
      ? `
    width: ${
      type === 'END' || type === 'START' ? '17.5px' : 'calc(100% + 1px)'
    };
  `
      : ''
  }
}

${theme.mediaQueries.laptopUpDS} {  
  ${
    $isHovered
      ? `
    border-style: solid;
    z-index: 1;
    width: ${
      type === 'END' || type === 'START'
        ? yearCalendar
          ? '9px'
          : '12.5px'
        : 'calc(100% + 1px)'
    };
  `
      : ''
  }
}
  `}
`;

const YearWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  text-align: center;
  height: ${({ month }) => (month === 'Jan' ? 'auto' : '100%')};

  ${MonthTitle} {
    width: 40px;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
    text-align: left;
  }

  ${WeekDay} {
    font-size: 0.75rem;
    padding: 0.5em;
  }

  ${Table} {
    height: "100%";
  }
`;

const Today = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
  outline: 1px solid ${({ theme }) => theme.colors.blackTwo};
  border-radius: 4px;
  pointer-events: none;
`;

const Available = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
  outline: '1px dashed hsla(335, 44%, 37%, 1)';
  border-radius: 4px;
  cursor: pointer;
`;

const WeekNumber = styled(Text)`
  position: absolute;
  left: 0.25rem;
  top: 0.25rem;
  font-size: 10px;
  font-weight: 700;
  line-height: 13px;

  ${({theme}) => theme.mediaQueries.laptopUpDS} {
    left: 0rem;
    top: 0.1rem;
    line-height: 1;
    font-size: 10px;
    font-weight: 700;
    color: ${DesignTokens.ColorBlackOpacity60};
  }
`;

export {
  Wrapper,
  YearWrapper,
  StyledSpinner,
  TableWrapper,
  MonthTitle,
  Table,
  DayBar,
  Td,
  DayNumber,
  Segment,
  Today,
  Available,
  WeekDay,
  WeekNumber,
};
