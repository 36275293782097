import React from 'react';
import dayjs from 'dayjs';
import {
  Wrapper,
  YearWrapper,
  StyledSpinner,
  TableWrapper,
  MonthTitle,
  Table,
  DayBar,
  WeekDay,
} from './Calendar.styles';
import PropTypes from 'prop-types';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { capitalise } from '@/helpers/stringUtils';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import BuildRows from './CalendarBuildRows';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(weekOfYear);

const Calendar = ({
  date,
  segments,
  unavailableDates,
  onDayClick,
  onSegmentClick,
  loading,
  disabled,
  yearCalendar,
  isFirstMonth,
  showWeekNumber,
  hoveredID,
  setHoveredID
}) => {
  const weekdays = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];
  const yearViewMonthLength = 37;

  const findSegments = (currentDate) => {
    const results = [];

    if (segments) {
      segments.forEach(({ id, startDate, endDate, state }) => {
        const start = dayjs(startDate);
        const end = dayjs(endDate);

        if (currentDate.isSame(start, 'day')) {
          results.push({ id, type: 'START', state });
        } else if (currentDate.isBetween(start, end, 'day')) {
          results.push({ id, type: 'DURING', state });
        } else if (currentDate.isSame(end, 'day')) {
          results.push({ id, type: 'END', state });
        }
      });
    }

    return results;
  };

  if (yearCalendar) {
    const yearWeekdays = [];
    while (yearWeekdays.length < yearViewMonthLength) {
      yearWeekdays.push(...weekdays);
    }

    return (
      <YearWrapper month={date.format('MMM')}>
        <MonthTitle>{capitalise(date.format('MMM'))}</MonthTitle>
        {loading && <StyledSpinner />}
        <TableWrapper hidden={loading}>
          {date.month() === 0 && (
            <DayBar>
              <tbody>
                <tr>
                  {Array.from({ length: yearViewMonthLength }).map((_, i) => {
                    const day = dayjs().day(i + 1);
                    return (
                      <WeekDay key={i} day={i} data-test={day.toISOString()}>
                        {capitalise(day.format('dd'))}
                      </WeekDay>
                    );
                  })}
                </tr>
              </tbody>
            </DayBar>
          )}
          <Table onMouseLeave={() => setHoveredID(null)}>
            <tbody>
              <BuildRows
                date={date}
                unavailableDates={unavailableDates}
                onDayClick={onDayClick}
                onSegmentClick={onSegmentClick}
                disabled={disabled}
                isFirstMonth={isFirstMonth}
                yearCalendar={yearCalendar}
                findSegments={findSegments}
                hoveredID={hoveredID}
                setHoveredID={setHoveredID}
                showWeekNumber={showWeekNumber}
              />
            </tbody>
          </Table>
        </TableWrapper>
      </YearWrapper>
    );
  }

  return (
    <Wrapper>
      {loading && <StyledSpinner />}
      <TableWrapper hidden={loading}>
        <MonthTitle>
          {capitalise(date.format('MMMM'))} {date.year()}
        </MonthTitle>
        {
          <DayBar>
            <tbody>
              <tr>
                {weekdays.map((_, i) => {
                  const day = dayjs().day(i + 1);
                  return (
                    <WeekDay key={i} day={i} data-test={day.toISOString()}>
                      {capitalise(day.format('dd'))}
                    </WeekDay>
                  );
                })}
              </tr>
            </tbody>
          </DayBar>
        }
        <Table onMouseLeave={() => setHoveredID(null)}>
          <tbody>
            <BuildRows
              date={date}
              unavailableDates={unavailableDates}
              onDayClick={onDayClick}
              onSegmentClick={onSegmentClick}
              disabled={disabled}
              isFirstMonth={isFirstMonth}
              yearCalendar={yearCalendar}
              findSegments={findSegments}
              hoveredID={hoveredID}
              setHoveredID={setHoveredID}
              showWeekNumber={showWeekNumber}
            />
          </tbody>
        </Table>
      </TableWrapper>
    </Wrapper>
  );
};

Calendar.propTypes = {
  date: PropTypes.object.isRequired,
  segments: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      color: PropTypes.string,
      state: PropTypes.string,
    })
  ),
  unavailableDates: PropTypes.arrayOf(PropTypes.any),
  onDayClick: PropTypes.func,
  onSegmentClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  yearCalendar: PropTypes.bool,
  isFirstMonth: PropTypes.bool,
  hoveredID: PropTypes.string,
  setHoveredID: PropTypes.func.isRequired,
  showWeekNumber: PropTypes.bool
};

Calendar.displayName = 'Calendar';

export default Calendar;
