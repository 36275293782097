import React, { useState, useEffect, useMemo } from 'react'
import SelectDropDownContainer from '../SelectDropDownContainer/SelectDropDownContainer'
import {
    MainHeaderSelectContainer,
    ExplanationText,
    BulletText,
} from './PriceRecommendations.styles'
import PriceRecommendationsFooter from '../PriceRecommendationsFooter/PriceRecommendationsFooter'
import { useQueryPropertyPricingSummary } from '@/hooks/useQuery'
import { Spinner } from '@/components/__UI'
import { useTranslation } from 'react-i18next'
import PriceRecommendationsTable from '../PriceRecommendationsTable/PriceRecommendationsTable'
import { Heading } from '@awaze/design-system.components.text'
import { useUser } from '@/context/UserProvider'
import { useUserBrand } from '@/hooks/useUserBrand/useUserBrand'

const PriceRecommendations = () => {
    const { t } = useTranslation()
    const { user } = useUser()
    const userBrand = useUserBrand()

    const { data, isLoading, isError } = useQueryPropertyPricingSummary()

    const filteredProperties = useMemo(() => data?.referenceData || [], [data])

    const [
        filteredPropertiesWithNames,
        setFilteredPropertiesWithNames,
    ] = useState([])
    const [selectedProperty, setSelectedProperty] = useState(null)

    useEffect(() => {
        if (filteredProperties && filteredProperties.length > 0) {
            setSelectedProperty(filteredProperties[0])
        }
    }, [filteredProperties])

    const durationLabels = {
        2: t('twoNights'),
        3: t('threeNights'),
        4: t('fourNights'),
        7: t('sevenNights'),
        14: t('forteenNights'),
    }

    const mapToYearOptions = (items) => {
        return items.map((item) => ({ label: item.year, value: item.year }))
    }
    const mapToDurationOptions = (items) => {
        return items.map((item) => ({
            label: durationLabels[item],
            value: item,
        }))
    }
    function selectDefaultYear(years) {
        return years && years.length > 0
            ? years.sort((a, b) => a.value - b.value)[0]
            : null
    }
    function selectDefaultDuration(durations) {
        return (
            durations?.find((x) => x.value.toString() === '7') ?? durations[0]
        )
    }

    const yearList = useMemo(() => {
        const years = filteredProperties?.find(
            (property) => selectedProperty?.propertyId === property.propertyId
        )?.years

        return years?.length ? mapToYearOptions(years) : []
    }, [selectedProperty, filteredProperties])

    const [selectedYear, setSelectedYear] = useState(
        selectDefaultYear(yearList)
    )

    const durationList = useMemo(() => {
        const years = filteredProperties?.find(
            (property) => selectedProperty?.propertyId === property.propertyId
        )?.years
        const durations = years?.find(
            (year) => year.year === selectedYear?.value
        )?.duration

        return durations?.length ? mapToDurationOptions(durations) : []
        // eslint-disable-next-line
    }, [selectedProperty, selectedYear, filteredProperties])

    const [selectedDuration, setSelectedDuration] = useState(
        selectDefaultDuration(durationList)
    )

    useEffect(() => {
        if (!yearList.find((item) => item.value === selectedYear?.value)) {
            setSelectedYear(selectDefaultYear(yearList))
        }
    }, [selectedYear, yearList])

    useEffect(() => {
        if (
            !durationList.find((item) => item.value === selectedDuration?.value)
        ) {
            setSelectedDuration(selectDefaultDuration(durationList))
        }
    }, [selectedDuration, durationList])

    const explanationText = () => {
        return (
            <>
                <ExplanationText data-testid='explanationText'>
                    <div className='explanationParagraph'>
                        {t(
                            `recommendedPricingIntroText1_${user.attributes.brand}`,
                            t('recommendedPricingIntroText1')
                        )}
                        <br />
                        <br />
                        {userBrand.isUserBrandAwaze ? (
                            <>
                                <ul id='awazeExtraDetailContainer'>
                                    <BulletText>
                                        All pricing values reflect customer
                                        prices.
                                    </BulletText>
                                    <BulletText>
                                        Pricing has been adjusted for
                                        differences in peak dates—for example,
                                        Easter falls two weeks earlier in 2026.
                                    </BulletText>
                                    <BulletText>
                                        The &quot;Achieved Price&quot; columns
                                        show the actual price at which your
                                        property was booked. If no bookings were
                                        made for a specific week, a &#39;0&#39;
                                        will appear in that column.
                                    </BulletText>
                                    <BulletText>
                                        Any bookings already made for this year
                                        will be honoured at the current agreed
                                        rate.
                                    </BulletText>
                                    <BulletText>
                                        If you choose not to accept the
                                        recommendations, your 2026 pricing will
                                        mirror your 2025 start prices.
                                    </BulletText>
                                </ul>
                                <br />
                            </>
                        ) : (
                            <></>
                        )}
                        {t(
                            `recommendedPricingIntroText2_${user.attributes.brand}`,
                            t('recommendedPricingIntroText2')
                        )}
                        <br />
                    </div>
                </ExplanationText>
                <Heading sizes={100} as='h2'>
                    {t('priceRecommendationsHeadingStep1')}
                </Heading>
                <ExplanationText>
                    <br />
                    {t('priceRecommendationsStep1message1')}
                </ExplanationText>
            </>
        )
    }

    if (filteredProperties.length === 0 && !isLoading && !isError)
        return <p>{t('noPropertiesFound')}</p>
    if (isError) return <p>{t('errorMessageSomethingWentWrong')}</p>

    return (
        <MainHeaderSelectContainer>
            {isLoading ? (
                <Spinner large data-testid='price-recommendation-spinner' />
            ) : (
                <>
                    {explanationText()}

                    <SelectDropDownContainer
                        data-testid='prPropertyDropdownContainer'
                        filteredProperties={filteredProperties}
                        setFilteredPropertiesWithNames={
                            setFilteredPropertiesWithNames
                        }
                        duration={selectedDuration}
                        onPropertyChange={setSelectedProperty}
                        durationData={{
                            value: selectedDuration,
                            options: durationList,
                            onChange: setSelectedDuration,
                        }}
                        yearData={{
                            value: selectedYear,
                            options: yearList,
                            onChange: setSelectedYear,
                        }}
                        hidePriceDuration={data.isHidePricingDurations}
                    />
                    {selectedProperty && selectedDuration && selectedYear && (
                        <PriceRecommendationsTable
                            property={selectedProperty}
                            duration={selectedDuration}
                            year={selectedYear}
                        />
                    )}
                    <article id='stepTwo'>
                        <PriceRecommendationsFooter
                            filteredProperties={filteredPropertiesWithNames}
                        />
                    </article>
                </>
            )}
        </MainHeaderSelectContainer>
    )
}

export default PriceRecommendations
