import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MessageWrapperStyled, ButtonStyled } from './CustomOwnerMessageBannerCollection.styles'
import { useQueryUserMessages } from '@/hooks/useQuery/queries/useQueryUserMessages'
import { useNavigate } from 'react-router-dom'
import { getMessageBannerClickUrl } from './MessageBannerClickUrl'
import { useUser } from '@/context/UserProvider'


const allMessageTypes = ['newPriceRecommendations', 'newRenewal']

const CustomOwnerMessageBannerCollection = () => {
  const { data: userMessages } = useQueryUserMessages()

  const { t } = useTranslation()
  const { user } = useUser()

  const navigate = useNavigate()

  const onClickMessageBanner = (messageType, propertyId, year) => {
    navigate(getMessageBannerClickUrl(messageType, propertyId, year))
  }

  const validMessages = useMemo(() =>
      userMessages?.filter(({ messageType }) => allMessageTypes.includes(messageType))
  , [userMessages])

  const hasValidMessages = !!(validMessages?.length)

  return (
    <>
      {hasValidMessages && validMessages.map(({ messageType, messageId, variables }) => {
        const { propertyId, year } = variables || {}
        return (
          <MessageWrapperStyled key={messageId} colourScheme="white" shadow={4} padding={[16, 16, 16]}>
            {t(`messageType:${messageType}:text_${user?.attributes.brand}`, t(`messageType:${messageType}:text`, variables))}
            <ButtonStyled
              colourScheme="brand-secondary"
              onClick={() => onClickMessageBanner(messageType, propertyId, year)}
              text={t(`messageType:${messageType}:linkText`, variables)}
              variant="text"
            />
          </MessageWrapperStyled>
        )
      })}
    </>
  )
}

export default CustomOwnerMessageBannerCollection
