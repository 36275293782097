import { useQueryUserAuthorisations, useQueryUserProperties } from '@/hooks/useQuery';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

type SelectedPropertyContextType = {
    propertyID: string | null
    setPropertyID: (id: string) => void
    isLoading: boolean
}

export const SelectedPropertyContext = createContext<SelectedPropertyContextType | undefined>(undefined);

export function SelectedPropertyProvider({ children }: { children: React.ReactNode }) {

    const activeRentalInitialValue = getIntialSelectedPropertyID()
    const [propertyID, setPropertyID] = useState<string | null>(activeRentalInitialValue);

    const { fallbackPropertyID, allPropertyIDs, isLoading } = useFallbackSelectedPropertyID()
    
    useEffect(() => {
        if (propertyID) {
            sessionStorage.setItem('activeRental', propertyID)
        }
    }, [propertyID])

    useEffect(() => {
        if (isLoading === false) {
            if (propertyID === null && fallbackPropertyID) {
                setPropertyID(fallbackPropertyID)
            }
            if (propertyID !== null && allPropertyIDs?.length > 0 && !allPropertyIDs.includes(propertyID)) {
                setPropertyID(fallbackPropertyID)
            }
        }
    }, [propertyID, fallbackPropertyID, allPropertyIDs, isLoading])

    const value = useMemo(() => ({ propertyID, setPropertyID, isLoading }), [propertyID, setPropertyID, isLoading])
    console.log('SelectedPropertyProvider', value)

    return (
        <SelectedPropertyContext.Provider value={value}>
            {children}
        </SelectedPropertyContext.Provider>
    )
}

function getIntialSelectedPropertyID() {
    // GET FROM url search param 'rentalId'
    const queryValue = new URLSearchParams(window.location.search).get('rentalId')
    const sessionValue = sessionStorage.getItem('activeRental');
    return queryValue || sessionValue || null
}

export function useSelectedPropertyID() {
    const context = useContext(SelectedPropertyContext)
    if (context === undefined) {
        throw new Error('useSelectedPropertyContext must be used within a SelectedPropertyProvider')
    }
    return context
}

function useFallbackSelectedPropertyID () {
  const { data: userAuthorisations } = useQueryUserAuthorisations()
  const hasUserAnyValidAuthorisations = userAuthorisations?.some(auth => auth.name !== 'home' && auth.authorised)

  const { data, isLoading } = useQueryUserProperties('', hasUserAnyValidAuthorisations)
  const fallbackPropertyID = data?.sites[0]?.siteProperties[0]?.propertyId ?? 'none'
  const allPropertyIDs = data?.sites.flatMap(site => site?.siteProperties.map(property => property.propertyId)) ?? []
  return { fallbackPropertyID, allPropertyIDs, isLoading }
}


