import React from 'react'
import { PageWrapper } from '@/components/__UI'
import PriceRecommendations from '../../components/__PriceRecommendations/PriceRecommendations/PriceRecommendations'
import { useTranslation } from 'react-i18next'
import { PriceContainer } from './PriceRecommendationsPage.styles'
import { useUser } from '@/context/UserProvider'

const PriceRecommendationsPage = () => {
    const { t } = useTranslation()
    const { user } = useUser()

    const title = t(
        `newSeasonPricingProposal_${user.attributes.brand}`,
        t('newSeasonPricingProposal')
    )

    return (
        <PageWrapper title={title}>
            <PriceContainer>
                <PriceRecommendations />
            </PriceContainer>
        </PageWrapper>
    )
}

export default PriceRecommendationsPage
