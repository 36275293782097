import { useMemo } from 'react'
import { useSelectedPropertyID } from '@/context/SelectedPropertyProvider'

export function useUrlMappings () {
  const { propertyID, isLoading } = useSelectedPropertyID()

  const urlMappings = useMemo(() => [
    { awaze: '/pricing-list', novasol: `/rental/${propertyID}/prices` },
    { awaze: '/bookings/calendar', novasol: `/rental/${propertyID}/blocking` },
    { awaze: '/bookings/list', novasol: `/rental/${propertyID}/bookings` },
    { awaze: '/financial/statements', novasol: `/rental/${propertyID}/documents` },
    { awaze: '/news', novasol: `/articles/191` }
  ], [propertyID])

  return { urlMappings, isLoading }
}

export function getMappedUrl (url, urlMappings, targetBrand, sourceBrand = 'awaze') {
  const mapping = urlMappings.find(x => x[sourceBrand] === url)
  return mapping?.[targetBrand] ?? url
}

export function useMappedUrl (path, brand) {
  const { urlMappings, isLoading } = useUrlMappings()

  const url = getMappedUrl(path, urlMappings, brand)

  return { url, isLoading }
}
