import dayjs from 'dayjs'
import theme from '@/global/Theme'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { PageWrapperCalendar, Legend } from '@/components/__UI'
import { BookingCalendar } from '@/components/__BookingComponents'
import {
  DropdownWrapper,
  PropertyDropdownWrapper,
  PropertyUnitDropdownWrapper,
  PropertyDropdown,
  LegendWrapper,
  BookingLegend,
  PropertyUnitDropdown
} from './Calendar.styles'
import ToolTipModal from '@/components/tooltip-modal/ToolTipModal'
import { useDisclosure } from '@awaze/design-system.utils.react'
import CustomOwnerMessageBannerCollection from '@/components/PriceRecommendationsBanner/CustomOwnerMessageBannerCollection'
import IconClosed from '@/assets/icons/IconClosed.svg'
import { usePropertyFromId } from '@/hooks/usePropertyFromID'
import useWindowSize from '@/hooks/useWindowSize'
import { useUserBrand } from '@/hooks/useUserBrand/useUserBrand'
import { Text } from '@awaze/design-system.components.text';

const BookingsPage = () => {
  const [ year, setYear ] = useState(dayjs().year())

  const { t } = useTranslation()

  const [ selectedPropertyID, setSelectedPropertyID ] = useState(null)
  const [ selectedPropertyUnitID, setSelectedPropertyUnitID ] = useState(null)
  const selectedProperty = usePropertyFromId(selectedPropertyID)

  const { open, isOpen, close } = useDisclosure()
 
  const {isUserBrandNovasol} = useUserBrand()

  useEffect(() => {
    return () => {
      setYear(dayjs().year().toString())
      setSelectedPropertyID(null)
    }
  }, [])

  const onClick = () => { open() }

  const { width } = useWindowSize()
  const isDesktop = width >= 1024

  return (
    <PageWrapperCalendar>
      <CustomOwnerMessageBannerCollection/>
      <h1>{t('calendar')}</h1>
      <Text>
        <p>{t('clickOrTapNewBooking')}</p>
      </Text>
      <BookingLegend>
        <DropdownWrapper>
          <PropertyDropdownWrapper>
            <PropertyDropdown
              label
              operationID="viewBookings"
              value={selectedPropertyID}
              onChange={event => setSelectedPropertyID(event.target.value)}
              autoSelectFirst
            />
          </PropertyDropdownWrapper>
          <PropertyUnitDropdownWrapper>
            <PropertyUnitDropdown
              label
              operationID="viewBookings"
              value={selectedPropertyUnitID}
              onChange={(event) => setSelectedPropertyUnitID(event.target.value)}
              autoSelectFirst
              hideSingleOption
              selectedPropertyID={selectedPropertyID}
            />
          </PropertyUnitDropdownWrapper>
        </DropdownWrapper>
        <LegendWrapper>
        <Legend
          items={[
          { label: t('guestBooking'), color: theme.colors.customer },
          { label: t('ownerBooking'), color: theme.colors.owner },
          { label: t('changeover'), color: theme.colors.changeOver },
          { label: t('other'), color: theme.gradient.candyCane },
          { label: t('provisional'), color: theme.colors.provisional },
          { label: t('closed'), icon: IconClosed }
        ]}
        tooltipText={t('bookingLegendTooltip')}
        onClick={onClick}
      />

          <ToolTipModal isOpen={isOpen} close={close} />
        </LegendWrapper>
      </BookingLegend>
      <BookingCalendar
        property={selectedProperty}
        unitId={selectedPropertyUnitID}
        year={year}
        setYear={setYear}
      />
    </PageWrapperCalendar>
  )
}

export default BookingsPage
